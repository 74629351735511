<template>
  <div class="sign-page">
    <v-row>
      <!-- page content -->
      <v-col cols="12" md="6" class="white pt-14">
        <div class="fix-bg white z-2"></div>
        <div
          class="sign-page__content text-center d-flex align-center justify-center h-100 z-4 relative mt-6"
        >
          <div class="w-100 sign-page__content__form mt-6">
            <!-- logo -->
            <img
              class="mt-5 cursor-pointer"
              width="200px"
              @click="$router.push('/')"
              :src="require('@/assets/images/global/logo.png')"
              alt=""
            />
            <span class="d-block primary--text font-500 font-20"
              >{{ $t("pleaseInsertCode") }}{{ email }}</span
            >
            <!-- <span class="d-block primary--text font-500 font-20"
              >Please insert code that sent to your mail or activate it from
              your mail</span
            > -->

            <span class="d-block primary--text font-500 font-30 mt-12">{{
              $t("verify")
            }}</span>

            <!-- form -->
            <v-form class="w-100 mt-5" @submit.prevent="submitHandler">
              <CodeInput
                :loading="false"
                class="input mx-auto"
                v-on:change="onChange"
                v-on:complete="onComplete"
                :fields="6"
              />
              <!-- actions -->
              <submit-btn
                title="verify"
                :loading="loading"
                @click="submitHandler"
              >
              </submit-btn>
            </v-form>
          </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="sign-page__img lightbluebg"
        v-if="!$vuetify.breakpoint.mobile"
      >
        <div class="sign-page__img__layout lightbluebg"></div>

        <img :src="require('@/assets/images/auth/bg.png')" alt="" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    valid: false,
    user: { code: "" },
  }),
  computed: {
    ...mapGetters(["fcmToken"]),
    email() {
      return localStorage.getItem("verifyEmail");
    },
  },
  methods: {
    onComplete(v) {
      this.user.code = v;
    },
    async submitHandler() {
      this.$store.dispatch('loading',true);
      if (this.user.code.length < 6) {
        this.$store.dispatch("showSnack", {
          text: this.$t("messages.mustBe6"),
          color: "error",
        });
      this.$store.dispatch('loading',false);
        return;
      }

      let formData = new FormData();
      formData.append("email", localStorage.getItem("verifyEmail"));
      formData.append("code", this.user.code);
      const res = await this.$axios.post("/codeVerify", formData);

      if (res.data && res.data.success) {
        res.data.userLogged = true;
        this.$store.dispatch("setUser", res.data);
        this.$store.dispatch("showSnack", {
          text: this.$t("messages.loggedSuccessfully"),
          color: "success",
        });
        this.$axios.defaults.headers.common = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        let fData = new FormData();
        fData.append("token", this.fcmToken);
        await this.$axios.post("/save-token", fData);
        this.$router.replace("/");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message || Object.values(res.data)[0].toString(),
          color: "error",
        });
      }
      this.$store.dispatch('loading',false);

    },
  },
};
</script>

<style lang="scss">
.sign-page {
  &__content {
    padding: 70px 20px;
    max-width: 550px;
    margin: auto;
  }

  &__img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__layout {
      position: absolute;
      bottom: -140px;
      width: 100%;
      height: 200px;
    }
  }
}
.fix-bg {
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0px;
  bottom: 0px;
}
</style>
